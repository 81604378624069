import React, {useContext, useEffect, useState} from 'react';
import {injectIntl} from "react-intl";
import { useDataLayer } from "dataLayerHelper";
import ListItem from "@Components/ListItem";
import Loading from "@Components/Loading";
import SectionHeader from "@Components/SectionHeader";
import Modal from "@Components/Modal";
import Button from "@FormElements/Button";
import Form from 'form';
import FormContext from "formContext";
import useDateHelper from "dateHelper";
import {useRegionsList} from "i18nHelper";
import {Helmet} from 'rnd-helmet';
import SubMenu from "@Components/SubMenu";
import axios from "axios";
import Logger from '@ffw/logger-lib';
import QualificationsList from "@Components/QualificationsList";
import WorkExperienceList from "@Components/WorkExperienceList";
import SkillsList from "@Components/SkillsList";
import moment from "moment";
import PersonalDataInfo from "@Components/PersonalDataInfo";
import useReplaceInitials from "useReplaceInitials";

function PersonalInformation({intl, staticContext, match}) {
  const {
    formData,
    setFormData,
    handleErrors,
    currentStep,
    getFormSchema,
    showToast,
    setToastMessage,
    setErrors,
    additionalData,
    setAdditionalData,
    timesRendered,
    triggerRerender,
    handleChange
  } = useContext(FormContext);
  const {user, specialisms, countries, qualifications, workXp, skills, languages, dsarSettings} = additionalData;
  const {locale} = intl;
  const nameDetailsForm = "name-details";
  const personalDetailsForm = "personal-details";
  const contactDetailsForm = "contact-details";
  const addressDetailsForm = "address-details";
  const resumeForm = "user-resume";
  const graduationForm = "user-graduation";
  const residentialAddressForm = "residential-address";
  const postalAddressForm = "postal-address"
  const emergencyContactForm = "emergency-contact-details";
  const degreesFormName = "user-degrees";
  const languageLevelsForm = "user-language-levels";
  const {regions} = useRegionsList(locale);
  const {customDateFormat} = useDateHelper();
  const [formName, setFormName] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const DATE_FORMAT = "d M Y";
  const [loading, setLoading] = useState(false);

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext);

  useEffect(() => {
    if (window.__ROUTE_DATA__) {
      delete window.__ROUTE_DATA__;
    }
    else {
      axios.get(`${process.env.REACT_APP_API_PREFIX}/user-data?currentLanguage=${locale}`)
        .then(response => {
          if (response.data.redirect === true && response.data.location) {
            window.location.href = response.data.location;
          }
          else {
            setAdditionalData(prevState => ({
              ...prevState,
              ...response.data
            }));
          }
        });
    }
  }, [timesRendered]);

  if (!user && !qualifications && !workXp && !skills ) {
    return <Loading/>
  }

  // Handle different naming on AT.
  let postalCode = !user.postal_code && user.post_code ? user.post_code : user.postal_code;
  if (postalCode !== undefined) {
    user.postal_code = postalCode;
  }

  const handleSubmit = event => {
    event.preventDefault();

    setLoading(true);
    let submitApiPath;
    switch (formName) {
      case nameDetailsForm:
        submitApiPath = '/user/name-details/';
        break;
      case personalDetailsForm:
        submitApiPath = '/user/personal-details/';
        break;
      case contactDetailsForm:
        submitApiPath = '/user/contact-details/';
        break;
      case addressDetailsForm:
        submitApiPath = '/user/address-details/';
        break;
      case resumeForm:
        submitApiPath = '/user/resume/';
        break;
      case residentialAddressForm:
        submitApiPath = '/user/residential-address/';
        break;
      case postalAddressForm:
        submitApiPath = '/user/postal-address';
        break;
      case emergencyContactForm:
        submitApiPath = '/user/emergency-contact-details';
        break;
      case graduationForm:
      case degreesFormName:
        submitApiPath = "/user/education-details/"
        break;
      case languageLevelsForm:
        submitApiPath = "/user/languages/"
    }
    if (!submitApiPath) {
      Logger.info(`Unable to update data. Missing API path for ${formName} form`, "Profile/profile-personal-details");
      return null;
    }

    axios.put(`${process.env.REACT_APP_API_PREFIX}${submitApiPath}`, formData[currentStep])
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          if (window && window.initAuthWidget && window.myRandClient && submitApiPath === "/user/name-details/") {
            // Name might have changed. Fetch refresh-token, which will also update MyRandInfo cookie, and
            // then replace user names and initials in the header.
            const replaceInitials = useReplaceInitials(formData[currentStep]);
            window.myRandClient.refreshToken(replaceInitials);
          }
          setModalOpen(false);
          setFormData([]);
          setErrors();
          setLoading(false);
          setTimeout(() => {
            const message = intl.formatMessage({id: 'Profile.AccountUpdated.Toast.SuccessMessage'});
            setToastMessage(message);
            showToast();
          }, 500);
          triggerRerender();
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrors(error.response.data);
      });
  };

  const onCloseModal = () => {
    setFormData([]);
    setErrors();
    setFormName('');
    setModalOpen(false)
  };

  const handleEditNameDetails = () => {
    let dataForForm = {};
    if (process.env.REACT_APP_FIELD_TITLE === "true") {
      dataForForm.title = user.title;
    }
    if (process.env.REACT_APP_FIELD_FIRST_NAME === "true") {
      dataForForm.first_name = user.first_name;
    }
    if (process.env.REACT_APP_FIELD_MIDDLE_NAME === "true") {
      dataForForm.middle_name = user.middle_name;
    }
    if (process.env.REACT_APP_FIELD_LAST_NAME === "true") {
      dataForForm.last_name = user.last_name;
    }

    if (["gr"].includes(process.env.REACT_APP_COUNTRY)) {
      dataForForm.accept_terms_conditions = user.accept_terms_conditions;
      dataForForm.accept_privacy_policy = user.accept_privacy_policy;
    }

    setFormData([dataForForm]);
    setFormName(nameDetailsForm);
    setModalOpen(true);
  };

  const getJobTitleByJobTitleId = jobTitleId => {
    let allSpecialisms = specialisms && specialisms.Specialisms || "";

    // For other opcos data comes to specialisms.Specialisms but for CN it comes just in specialisms, which is better
    // In the future we can fix it for the other opcos to remove this specialisms.Specialisms
    if (process.env.REACT_APP_COUNTRY === "cn" && !allSpecialisms) {
      allSpecialisms = specialisms;
    }

    let jobTitleName = '';
    if (!allSpecialisms) {
      return jobTitleName;
    }
    allSpecialisms.forEach(specialism => {
      specialism.lstJobTitles.forEach(jobTitle => {
        if (jobTitle.JobTitleCode === jobTitleId) {
          jobTitleName = jobTitle.JobTitleValue;
        }
      })
    });
    return jobTitleName;
  };

  const handleEditPersonalDetails = () => {
    let dataForForm = {};
    if (process.env.REACT_APP_FIELD_DATE_OF_BIRTH === "true") {
      dataForForm.date_of_birth = user.date_of_birth;
    }
    if (process.env.REACT_APP_FIELD_FISCAL_NUMBER === "true") {
      dataForForm.NIF = user.NIF;
    }
    if (process.env.REACT_APP_FIELD_EU_CITIZEN === "true") {
      dataForForm.eu_citizen = user.eu_citizen;
    }
    if (process.env.REACT_APP_FIELD_NATIONALITY === "true") {
      dataForForm.nationality = user.nationality;
    }
    if (process.env.REACT_APP_FIELD_GENDER === "true") {
      dataForForm.gender = user.gender;
    }
    if (process.env.REACT_APP_FIELD_SPECIALISM === "true") {
      dataForForm.specialism = user.specialism;
    }
    if (process.env.REACT_APP_FIELD_JOB_TITLE === "true") {
      dataForForm.job_title_id = user.job_title_id;
      dataForForm.job_title = user.job_title_id ? getJobTitleByJobTitleId(user.job_title_id) : '';
    }
    if (process.env.REACT_APP_FIELD_LANGUAGES_AUTOCOMPLETE === "true") {
      // TODO: languages to be removed later, we are now saving it as a empty field in dynamodb
      //  in order to properly delete the column when from is saved.
      dataForForm.languages = '';
      dataForForm.language_ids = user.language_ids;
    }
    if (process.env.REACT_APP_FIELD_UNDESIRABLE_CLIENTS === "true") {
      dataForForm.ltx_undesirable = user.ltx_undesirable
    }

    setFormData([dataForForm]);
    setFormName(personalDetailsForm);
    setModalOpen(true);
  };

  const handleEditContactDetails = () => {
    let dataForForm = {};
    if (process.env.REACT_APP_FIELD_PHONE === "true") {
      dataForForm.phone = user.phone;
    }
    if (process.env.REACT_APP_FIELD_EMAIL === "true") {
      dataForForm.email = user.email;
    }
    if (process.env.REACT_APP_FIELD_MARKETING_EMAILS === "true") {
      dataForForm.subscribe_marketing = user.subscribe_marketing;
    }
    if (["gr"].includes(process.env.REACT_APP_COUNTRY)) {
      dataForForm.accept_email_alert = user.accept_email_alert;
    }

    if (["gr"].includes(process.env.REACT_APP_COUNTRY)) {
      dataForForm.accept_terms_conditions = user.accept_terms_conditions;
      dataForForm.accept_privacy_policy = user.accept_privacy_policy;
    }

    setFormData([dataForForm]);
    setFormName(contactDetailsForm);
    setModalOpen(true);
  };

  const handleEditLanguageLevels = () => {
    let dataForForm = {};
    if (process.env.REACT_APP_FIELD_LANGUAGE_LEVEL_1 === "true") {
      dataForForm.language_1 = user.language_1;
      dataForForm.language_level_1 = user.language_level_1;
    }
    if (process.env.REACT_APP_FIELD_LANGUAGE_LEVEL_2 === "true") {
      dataForForm.language_2 = user.language_2;
      dataForForm.language_level_2 = user.language_level_2;
    }
    if (process.env.REACT_APP_FIELD_LANGUAGE_LEVEL_3 === "true") {
      dataForForm.language_3 = user.language_3;
      dataForForm.language_level_3 = user.language_level_3;
    }

    setFormData([dataForForm]);
    setFormName(languageLevelsForm);
    setModalOpen(true);
  };

  const handleEditAddressDetails = () => {
    let dataForForm = {};
    if (process.env.REACT_APP_FIELD_STREET === "true") {
      dataForForm.street = user.street;
    }
    if (process.env.REACT_APP_FIELD_HOUSE_NUMBER === "true") {
      dataForForm.house_number = user.house_number;
    }
    if (process.env.REACT_APP_FIELD_POSTAL_CODE === "true") {
      dataForForm.postal_code = user.postal_code;
    }
    if (process.env.REACT_APP_FIELD_CITY === "true") {
      dataForForm.city = user.city;
    }
    if (process.env.REACT_APP_FIELD_REGION === "true") {
      dataForForm.region = user.region;
    }
    if (process.env.REACT_APP_FIELD_COUNTRY === "true") {
      dataForForm.country = user.country;
    }

    setFormData([dataForForm]);
    setFormName(addressDetailsForm);
    setModalOpen(true);
  };

  const handleEditResume = (event) => {
    let dataForForm = {};
    if (process.env.REACT_APP_FIELD_CV === "true") {
      dataForForm.CV = user.CV;
    }
    if (process.env.REACT_APP_FIELD_DOCUMENTS === "true") {
      dataForForm.other_documents = user.other_documents;
    }

    setFormData([dataForForm]);
    setFormName(resumeForm);
    setModalOpen(true);
  };

  const handleEditGraduation = () => {
    let dataForForm = {};
    if (process.env.REACT_APP_FIELD_GRADUATION === "true") {
      dataForForm.graduation = user.graduation;
    }
    setFormData([dataForForm]);
    setFormName(graduationForm);
    setModalOpen(true);
  };

  const getStateByRegionCode = (typeOfAddress) => {
    // We do not continue with the logic for APAC if the OpCo is not from APAC.
    const opcos = ["au", "hk" ,"my", "nz", "sg"];
    if (!opcos.includes(process.env.REACT_APP_COUNTRY)) {
      return typeOfAddress === "ResidentialAddress" ? user.residential_state : typeOfAddress === "PostalAddress" ? user.postal_state : '';
    }

    if (user[typeOfAddress] && user[typeOfAddress].Country && user[typeOfAddress].State) {
      const selectedCountryRegions = regions.find(country => country.countryName.toLowerCase() === user[typeOfAddress].Country.toLowerCase());
      if (selectedCountryRegions) {
        if (user[typeOfAddress].Country === 'Australia') {
          return selectedCountryRegions.countryRegions[user[typeOfAddress].State];
        }
        else {
          return user[typeOfAddress].State;
        }
      }
    }
    else {
      return '';
    }
  }

  // APAC and CN only handler
  const handleEditResidentialAddress = () => {
    let data = {};
    if (process.env.REACT_APP_COUNTRY === "cn") {
      data = {
        residential_country: user.residential_country ? user.residential_country : '',
        residential_state: user.residential_state ? user.residential_state : '',
        residential_city: user.residential_city ? user.residential_city : '',
        residential_address_one: user.residential_address_one ? user.residential_address_one : '',
        residential_address_two: user.residential_address_two ? user.residential_address_two : '',
      }
    } else {
      data = {
        country: user.residential_country ? user.residential_country : '',
        state: user.residential_state ? user.residential_state : '',
        city: user.residential_city ? user.residential_city : '',
        address_one: user.residential_address_one ? user.residential_address_one : '',
        address_two: user.residential_address_two ? user.residential_address_two : '',
      }
    }

    if (process.env.REACT_APP_FIELD_RESIDENTIAL_POSTAL_CODE === "true") {
      data.postal_code = user.residential_postal_code ? user.residential_postal_code : ''
    }

    setFormData([data]);
    setFormName(residentialAddressForm);
    setModalOpen(true);
  };

  // APAC and CN only handler
  const handleEditPostalAddress = () => {
    let data = {};
    if (process.env.REACT_APP_COUNTRY === "cn") {
      data = {
        postal_country: user.postal_country ? user.postal_country : '',
        postal_state: user.postal_state ? user.postal_state : '',
        postal_city: user.postal_city ? user.postal_city : '',
        postal_address_one: user.postal_address_one ? user.postal_address_one : '',
        postal_address_two: user.postal_address_two ? user.postal_address_two : '',
      }
    } else {
      data = {
        country: user.postal_country ? user.postal_country : '',
        state: user.postal_state ? user.postal_state : '',
        city: user.postal_city ? user.postal_city : '',
        address_one: user.postal_address_one ? user.postal_address_one : '',
        address_two: user.postal_address_two ? user.postal_address_two : '',
      }
    }

    if (process.env.REACT_APP_FIELD_RESIDENTIAL_POSTAL_CODE === "true") {
      data.postal_code = user.residential_postal_code ? user.residential_postal_code : ''
    }

    setFormData([data]);
    setFormName(postalAddressForm);
    setModalOpen(true);
  };

  // APAC and CN only handler
  const handleEditEmergencyContactDetails = () => {
    const data = {
      emergency_contact_name: user.EmergencyContactName ? user.EmergencyContactName : user.emergency_contact_name,
      emergency_contact_number: user.EmergencyContactNumber ? user.EmergencyContactNumber : user.emergency_contact_number,
    }

    setFormData([data]);
    setFormName(emergencyContactForm);
    setModalOpen(true);
  };

  const handleEditDegrees = () => {
    const {diploma, firstDegree, secondDegree, master} = user;
    setFormData([{diploma, firstDegree, secondDegree, master}]);
    setFormName(degreesFormName);
    setModalOpen(true);
  }

  const userFiles = (file = null, translation) => {
    // APAC OpCos have user.resume as the CV object.
    //  We do not check for file since the structure and format are different.
    if (user.resume) {
      user.CV = {
        filename: user.resume.DocumentName,
        size: user.resume.DocumentSize
      }
      return (
        <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
          <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResumeAndDocuments.Resume.Label'})}</div>
          <div className="my-environment-item__list-content mt-xxs l:mt-none"><a>{user.CV.filename}</a></div>
        </div>
      );
    }
    else if (file) {
      return (
        <>
          <div className="my-environment-item__list my-environment-item__list-details mt-s">
            <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: translation})}</div>
            <div className="my-environment-item__list-content mt-xxs"><a href="#" rel="noopener noreferrer" onClick={e => e.preventDefault()}>{file.filename}</a></div>
          </div>
        </>
      );
    }
    else {
      return null;
    }
  }

  const renderLanguages = () => {
    if (!user.languagesArray) {
      return ;
    }
    const languages = user.languagesArray;
    let renderedLanguages = [];
    languages.forEach((language, index) => {
      renderedLanguages.push(
        <span key={index} className="badge badge--tertiary badge--l">{language}</span>
      );
    });

    return renderedLanguages;
  }

  const modalFooter = () => {
    const modalButtonSettings = {
      medium: true,
      filled: true,
      fullWidth: true,
      text: getFormSchema(formName)[currentStep].modalButton,
      loading
    }

    return <Button onClick={handleSubmit} settings={modalButtonSettings} />
  }

  const renderModal = () => {
    if (!modalOpen || !formName) {
      return '';
    }

    return (
      <Modal
        title={intl.formatMessage({id: getFormSchema(formName)[currentStep].modalTitle})}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={true}
      >
        <Form name={formName} handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit}/>
      </Modal>
    );
  };

  const renderPhoto = () => {
    if (!user || !user.photo || !user.photo.url) {
      return '';
    }
    return (
      <div className="profile__photo">
        <img alt="profile photo" src={`${user.photo.url}`}/>
      </div>
    );
  };


  if ((process.env.REACT_APP_COUNTRY === "it" || process.env.REACT_APP_COUNTRY === "ch") && user && user.date_of_birth && moment(user.date_of_birth, "DD-MM-YYYY", true).isValid()) {
    user.date_of_birth_iso = moment(user.date_of_birth, "DD-MM-YYYY").format('YYYY-MM-DD');
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({id: 'Menu.Profile'})} | {intl.formatMessage({id: 'Breadcrumb.Dashboard'})}</title>
        <header className="header bg-variant-white header--s header--welcome"/>
      </Helmet>
      <SubMenu />
      <div className="block block--my-randstad block--xs">
      <div className="block__wrapper wrapper pt-l l:pt-xl">
        <SectionHeader title={intl.formatMessage({id: 'Profile.PersonalDetails.Section.Title'})} description={intl.formatMessage({id: 'PersonalInformation.Description'})}/>
        <div className="block__content block__content--align-right block__content--s">
            {
              process.env.REACT_APP_SECTION_NAME_DETAILS === "true" &&
              <ListItem
                title={intl.formatMessage({id: 'PersonalInformation.Name.List.Title'})}
                handleEdit={handleEditNameDetails}
                label={intl.formatMessage({id: 'PersonalInformation.Name.List.Title'})}
                divider="true"
              >
                <div className="avatar-info mt-s l:mt-m">
                  <div className="avatar avatar--XL avatar__initials mr-m">
                    {process.env.REACT_APP_FIELD_PHOTO === "true" && renderPhoto()}
                    {<span>{user.first_name ? user.first_name.charAt(0) : user.FirstName ? user.FirstName.charAt(0) : ''}{user.last_name ? user.last_name.charAt(0) : user.LastName ? user.LastName.charAt(0) : ''}</span>}
                  </div>
                  <div className="avatar-info__name mt-xs l:mt-none">
                    {process.env.REACT_APP_FIELD_TITLE === "true" && user.title ?
                      (process.env.REACT_APP_COUNTRY === 'ch' ? (user.title === 'male' ? intl.formatMessage({id: 'Gender.Male'}) : intl.formatMessage({id: 'Gender.Female'}))
                        : user.title)
                      : ''
                    } {process.env.REACT_APP_FIELD_FIRST_NAME === "true" && user.first_name ? user.first_name : user.FirstName ? user.FirstName : ''} {process.env.REACT_APP_FIELD_MIDDLE_NAME === "true" && user.middle_name ? user.middle_name : user.MiddleName ? user.MiddleName : ''} {process.env.REACT_APP_FIELD_LAST_NAME === "true" && user.last_name ? user.last_name : user.LastName ? user.LastName : ''}
                  </div>
                </div>
              </ListItem>
            }
            {
              process.env.REACT_APP_SECTION_PERSONAL_DETAILS === "true" &&
              <ListItem
                title={intl.formatMessage({id: 'PersonalInformation.PersonalDetails.List.Title'})}
                handleEdit={handleEditPersonalDetails}
                label={intl.formatMessage({id: 'PersonalInformation.PersonalDetails.List.Title'})}
                divider="true"
              >
                {
                  process.env.REACT_APP_FIELD_DATE_OF_BIRTH === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.PersonalDetails.BirthDay.Label'})}</div>
                    <div className="my-environment-item__list-content mt-xxs l:mt-none">
                      {user.date_of_birth_iso
                        ? customDateFormat(user.date_of_birth_iso, DATE_FORMAT) : user.date_of_birth
                          ? customDateFormat(user.date_of_birth, DATE_FORMAT) : '-'}
                    </div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_FISCAL_NUMBER === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'Profile.PersonalDetails.AboutMe.Nif.Label'})}</div>
                    <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.NIF ? user.NIF : '-'}</div>
                  </div>
                }
                {
                    process.env.REACT_APP_FIELD_EU_CITIZEN === "true" &&
                    <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                      <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.PersonalDetails.EuCitizen.Label'})}</div>
                      <div className="my-environment-item__list-content mt-xxs l:mt-none">{(user.eu_citizen === "yes") ? intl.formatMessage({id: 'EuCitizen.Eu'}) : intl.formatMessage({id: 'EuCitizen.NonEu'})}</div>
                    </div>
                }
                {
                  process.env.REACT_APP_FIELD_NATIONALITY === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.PersonalDetails.Country.Label'})}</div>
                    <div className="my-environment-item__list-content mt-xxs l:mt-none">{(user.nationality === "true" && countries === "true" && countries.hasOwnProperty(user.nationality)) ? countries[user.nationality] : '-'}</div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_GENDER === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.PersonalDetails.Gender.Label'})}</div>
                    <div className="my-environment-item__list-content mt-xxs l:mt-none">{(user.gender ? user.gender : '-')}</div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_SPECIALISM === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.PersonalDetails.Function.Label'})}</div>
                    <div className="my-environment-item__list-content mt-xxs l:mt-none">{(user.specialism ? user.specialism : '-')}</div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_JOB_TITLE === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.PersonalDetails.JobTitle.Label'})}</div>
                    <div className="my-environment-item__list-content mt-xxs l:mt-none">{(user.job_title_id ? getJobTitleByJobTitleId(user.job_title_id) : '-')}</div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_LANGUAGES_AUTOCOMPLETE === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.PersonalDetails.Languages.Label'})}</div>
                    <div className="my-environment-item__list-content mt-xxs l:mt-none">
                    <div className="element-group">
                      {renderLanguages()}
                    </div>
                  </div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_UNDESIRABLE_CLIENTS === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.PersonalDetails.Undesirable.Label'})}</div>
                    <div className="my-environment-item__list-content mt-xxs l:mt-none">
                      {user.ltx_undesirable ? user.ltx_undesirable : '-'}
                    </div>
                  </div>
                }
              </ListItem>
            }
            {
              process.env.REACT_APP_SECTION_CONTACT_DETAILS === "true" &&
              <ListItem
                title={intl.formatMessage({id: 'PersonalInformation.ContactDetails.List.Title'})}
                handleEdit={handleEditContactDetails}
                label={intl.formatMessage({id: 'PersonalInformation.ContactDetails.List.Title'})}
                divider="true"
              >
                {
                  process.env.REACT_APP_FIELD_PHONE === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div
                      className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ContactDetails.Phone.Label'})}</div>
                    <div
                      className="my-environment-item__list-content mt-xxs l:mt-none">{(user.phone ? user.phone : '-')}</div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_EMAIL === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div
                      className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ContactDetails.Email.Label'})}</div>
                    <div
                      className="my-environment-item__list-content mt-xxs l:mt-none">{(user.email ? user.email : '-')}</div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_MARKETING_EMAILS=== "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div
                      className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ContactDetails.Marketing.Label'})}</div>
                    <div
                      className="my-environment-item__list-content mt-xxs l:mt-none">{(user.subscribe_marketing ? user.subscribe_marketing : '-')}</div>
                  </div>
                }
                {
                    ["gr"].includes(process.env.REACT_APP_COUNTRY) &&
                    <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                      <div
                          className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'Profile.User.Checkbox.Alert.Email.Label'})}</div>
                      <div
                          className="my-environment-item__list-content mt-xxs l:mt-none">{user.accept_email_alert === "yes" ? intl.formatMessage({id: 'Profile.User.Accept.EmailAlerts.Yes'}) : intl.formatMessage({id: 'Profile.User.Accept.EmailAlerts.No'})}</div>
                    </div>
                }
              </ListItem>
            }
            {
              process.env.REACT_APP_SECTION_ADDRESS_DETAILS === "true" &&
              <ListItem
                title={intl.formatMessage({id: 'PersonalInformation.AddressDetails.List.Title'})}
                handleEdit={handleEditAddressDetails}
                label={intl.formatMessage({id: 'PersonalInformation.AddressDetails.List.Title'})}
                divider="true"
              >
                {
                  process.env.REACT_APP_FIELD_STREET === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div
                      className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.AddressDetails.StreetDetails.Label'})}</div>
                    <div
                      className="my-environment-item__list-content mt-xxs l:mt-none">{(user.street ? user.street : '-')}</div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_HOUSE_NUMBER === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div
                        className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.AddressDetails.HouseNumberDetails.Label'})}</div>
                    <div
                        className="my-environment-item__list-content mt-xxs l:mt-none">{(user.house_number ? user.house_number : '-')}</div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_POSTAL_CODE === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div
                      className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.AddressDetails.PostalCode.Label'})}</div>
                    <div
                      className="my-environment-item__list-content mt-xxs l:mt-none">{(user.postal_code ? user.postal_code : '-')}</div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_CITY === "true" && process.env.REACT_APP_COUNTRY !== "it" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div
                      className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.AddressDetails.City.Label'})}</div>
                    <div
                      className="my-environment-item__list-content mt-xxs l:mt-none">{(user.city ? user.city : '-')}</div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_REGION === "true" && process.env.REACT_APP_COUNTRY !== "it" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div
                      className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.AddressDetails.Region.Label'})}</div>
                    <div
                      className="my-environment-item__list-content mt-xxs l:mt-none">{(user.region ? user.region : '-')}</div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_COUNTRY === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div
                      className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.AddressDetails.Country.Label'})}</div>
                    <div
                      className="my-environment-item__list-content mt-xxs l:mt-none">{(user.country ? user.country : '-')}</div>
                  </div>
                }
              {/*  Italy specific conditional rendering for city and region */}
                {
                  process.env.REACT_APP_FIELD_CITY === "true" && process.env.REACT_APP_COUNTRY === "it" && user.city &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div
                      className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.AddressDetails.City.Label'})}</div>
                    <div
                      className="my-environment-item__list-content mt-xxs l:mt-none">{user.transformedCity}</div>
                  </div>
                }
                {
                  process.env.REACT_APP_FIELD_REGION === "true" && process.env.REACT_APP_COUNTRY === "it" && user.region &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div
                      className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.AddressDetails.Region.Label'})}</div>
                    <div
                      className="my-environment-item__list-content mt-xxs l:mt-none">{user.transformedRegion}</div>
                  </div>
                }
              </ListItem>
            }
            {
              process.env.REACT_APP_SECTION_CV_DOCUMENTS === "true" &&
              <ListItem
                title={intl.formatMessage({id: 'PersonalInformation.ResumeAndDocuments.List.Label'})}
                handleEdit={handleEditResume}
                label={intl.formatMessage({id: 'PersonalInformation.ResumeAndDocuments.List.Label'})}
                divider="true"
              >
                {
                  process.env.REACT_APP_FIELD_CV === "true" &&
                  userFiles(user.CV, 'PersonalInformation.ResumeAndDocuments.Resume.Label')
                }
                {
                  process.env.REACT_APP_FIELD_DOCUMENTS === "true" &&
                  userFiles(user.other_documents, 'PersonalInformation.ResumeAndDocuments.Documents.Label')
                }
              </ListItem>
            }
            {
              process.env.REACT_APP_SECTION_RESIDENTIAL_ADDRESS === "true" &&
              <ListItem
                title={intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.Title'})}
                handleEdit={handleEditResidentialAddress}
                label={intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.Title'})}
                divider="true"
              >
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.Country.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.residential_country ? user.residential_country : '-'}</div>
                </div>
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.State.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.residential_state ? getStateByRegionCode('ResidentialAddress') : '-'}</div>
                </div>
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.City.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.residential_city ? user.residential_city : '-'}</div>
                </div>
                {
                  process.env.REACT_APP_FIELD_RESIDENTIAL_POSTAL_CODE === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.PostalCode.Label'})}</div>
                    <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.residential_postal_code ? user.residential_postal_code : '-'}</div>
                  </div>
                }
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.Address1.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.residential_address_one ? user.residential_address_one : '-'}</div>
                </div>
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.Address2.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.residential_address_two ? user.residential_address_two : '-'}</div>
                </div>
              </ListItem>
            }
            {
              process.env.REACT_APP_SECTION_POSTAL_ADDRESS === "true" &&
              <ListItem
                title={intl.formatMessage({id: 'PersonalInformation.PostalAddress.Title'})}
                handleEdit={handleEditPostalAddress}
                label={intl.formatMessage({id: 'PersonalInformation.PostalAddress.Title'})}
                divider="true"
              >
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.Country.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.postal_country ? user.postal_country : '-'}</div>
                </div>
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.State.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.postal_state ? getStateByRegionCode('PostalAddress') : '-'}</div>
                </div>
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.City.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.postal_city ? user.postal_city : '-'}</div>
                </div>
                {
                  process.env.REACT_APP_FIELD_RESIDENTIAL_POSTAL_CODE === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.PostalCode.Label'})}</div>
                    <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.postal_postal_code ? user.postal_postal_code : '-'}</div>
                  </div>
                }
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.Address1.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.postal_address_one ? user.postal_address_one : '-'}</div>
                </div>
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.ResidentialAddress.Address2.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.postal_address_two ? user.postal_address_two : '-'}</div>
                </div>
              </ListItem>
            }
            {
              process.env.REACT_APP_SECTION_EMERGENCY_CONTACT === "true" &&
              <ListItem
                title={intl.formatMessage({id: 'PersonalInformation.EmergencyContact.Title'})}
                handleEdit={handleEditEmergencyContactDetails}
                label={intl.formatMessage({id: 'PersonalInformation.EmergencyContact.Title'})}
                divider="true"
              >
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.EmergencyContact.Name.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.emergency_contact_name ? user.emergency_contact_name : ''}</div>
                </div>
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.EmergencyContact.Number.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.emergency_contact_number ? user.emergency_contact_number : ''}</div>
                </div>
              </ListItem>
            }
            {
              process.env.REACT_APP_SECTION_GRADUATION === "true" &&
              <ListItem
                title={intl.formatMessage({id: 'PersonalInformation.Graduation.Section.Title'})}
                handleEdit={handleEditGraduation}
                label={intl.formatMessage({id: 'PersonalInformation.Graduation.Graduation.Label'})}
                divider="true"
              >
                {
                  process.env.REACT_APP_FIELD_GRADUATION === "true" &&
                  <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                    <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.Graduation.Graduation.Label'})}</div>
                    {
                      process.env.REACT_APP_COUNTRY === "gr" ? (
                        <div className="my-environment-item__list-content mt-xxs l:mt-none">{user && user.graduation}</div>
                      ) :
                        (
                          <div className="my-environment-item__list-content mt-xxs l:mt-none">{user && user.graduation && intl.formatMessage({id: `SelectField.GraduationType.${user.graduation}`})}</div>
                        )
                    }
                  </div>
                }
              </ListItem>
            }
            {
              process.env.REACT_APP_SECTION_DEGREES === "true" &&
              <ListItem
                title={intl.formatMessage({id: 'PersonalInformation.Graduation.Degrees'})}
                handleEdit={handleEditDegrees}
                label={intl.formatMessage({id: 'PersonalInformation.Graduation.Degrees'})}
                divider="true"
              >
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.Graduation.Diploma.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.transformedDiploma}</div>
                </div>
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.Graduation.FirstDegree.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.transformedFirstDegree}</div>
                </div>
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.Graduation.SecondDegree.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.transformedSecondDegree}</div>
                </div>
                <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                  <div className="my-environment-item__list-title text--alternative">{intl.formatMessage({id: 'PersonalInformation.Graduation.Master.Label'})}</div>
                  <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.transformedMaster}</div>
                </div>
              </ListItem>
            }
             <ListItem
                  divider="true"
              >
              {
                process.env.REACT_APP_SECTION_QUALIFICATIONS === "true" &&
                <QualificationsList qualifications={qualifications} />
              }
              {
                process.env.REACT_APP_SECTION_WORK_EXPERIENCES === "true" &&
                <WorkExperienceList workXp={workXp} />
              }
              {
                (process.env.REACT_APP_SECTION_SKILLS === "true" && process.env.REACT_APP_SECTION_LANGUAGE === "true") ?
                <SkillsList skills={skills} languages={languages} /> :
                  process.env.REACT_APP_SECTION_SKILLS === "true" && <SkillsList skills={skills} />
              }
            </ListItem>
            {
              process.env.REACT_APP_SECTION_PROFILE_LANGUAGE_LEVEL === "true" &&
              <ListItem
                  title={intl.formatMessage({id: 'PersonalInformation.LanguageLevels.List.Title'})}
                  handleEdit={handleEditLanguageLevels}
                  label={intl.formatMessage({id: 'PersonalInformation.LanguageLevels.List.Title'})}
                  divider="true"
              >
                {
                    (process.env.REACT_APP_FIELD_LANGUAGE_LEVEL_1 === "true" && user.language_1) &&
                    <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                      <div className="my-environment-item__list-title text--alternative">{user.rendered_language_1 ? user.rendered_language_1 : user.language_1}</div>
                      <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.rendered_language_level_1 ? user.rendered_language_level_1 : user.language_level_1}</div>
                    </div>
                }
                {
                   (process.env.REACT_APP_FIELD_LANGUAGE_LEVEL_2 === "true" && user.language_2) &&
                    <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                      <div className="my-environment-item__list-title text--alternative">{user.rendered_language_2 ? user.rendered_language_2 : user.language_2}</div>
                      <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.rendered_language_level_2 ? user.rendered_language_level_2 : user.language_level_2}</div>
                    </div>
                }
                {
                    (process.env.REACT_APP_FIELD_LANGUAGE_LEVEL_3 === "true" && user.language_3) &&
                    <div className="my-environment-item__list my-environment-item__list-details mt-s l:mt-xs">
                      <div className="my-environment-item__list-title text--alternative">{user.rendered_language_3 ? user.rendered_language_3 : user.language_3}</div>
                      <div className="my-environment-item__list-content mt-xxs l:mt-none">{user.rendered_language_level_3 ? user.rendered_language_level_3 : user.language_level_3}</div>
                    </div>
                }
              </ListItem>
            }
              {process.env.REACT_APP_DSAR_REQUEST === "true" &&
                <PersonalDataInfo dsarSettings={dsarSettings} />
              }
          </div>
        </div>
      </div>
      {renderModal()}
    </>
  )
}

export default injectIntl(PersonalInformation);
